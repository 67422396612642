<template>
  <admin-wrapper>
    <div>
      <div class="mb-24px">注册</div>
      <a-carousel :autoplay="false" :dots="false" ref="carouselRef">
        <a-form layout="vertical" :hideRequiredMark="true" autocomplete="false" class="register-form">
          <a-form-item label="手机号码" v-bind="validateInfos['user.cellphone']">
            <div class="flex">
              <a-button disabled>+86</a-button>
              <a-input
                :disabled="verifyOk"
                placeholder="11位手机号"
                v-model:value="formData.user.cellphone"
                @change="() => (formData.user.cellphone = formData.user.cellphone.replace(/[\D]/g, ''))"
              ></a-input>
            </div>
          </a-form-item>
          <a-form-item label="验证码" v-bind="validateInfos.verifyCode">
            <div class="flex">
              <a-input :disabled="verifyOk" placeholder="输入验证码" v-model:value="formData.verifyCode"></a-input>
              <a-button
                class="ml-[10px]"
                @click="formVerifyCodeClick"
                :disabled="Boolean(verifyCodeTime) || verifyOk"
                >{{ verifyCodeTime ? `重新获取${verifyCodeTime}s` : '获取验证码' }}</a-button
              >
            </div>
          </a-form-item>
          <a-form-item label="姓名" v-bind="validateInfos['user.name']">
            <a-input
              :maxlength="6"
              placeholder="请输入姓名"
              v-model:value="formData.user.name"
              autocomplete="off"
            ></a-input>
          </a-form-item>
          <a-form-item label="密码" v-bind="validateInfos['user.password']">
            <a-input
              placeholder="请设置密码"
              v-model:value="formData.user.password"
              type="password"
              autocomplete="off"
            ></a-input>
          </a-form-item>
          <a-form-item label="确认密码" v-bind="validateInfos['user.password2']">
            <a-input
              placeholder="请确认密码"
              v-model:value="formData.user.password2"
              type="password"
              autocomplete="new-password"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-form layout="vertical" :hideRequiredMark="true" autocomplete="false" class="register-form">
          <a-form-item label="公司简称" v-bind="validateInfos['supplier.shortName']">
            <a-input
              placeholder="请输入公司简称，至多6个字"
              :maxlength="6"
              v-model:value="formData.supplier.shortName"
            ></a-input>
          </a-form-item>
          <a-form-item label="地区选择" v-bind="validateInfos['supplier.areaCodes']">
            <e-select-area v-model="formData.supplier.areaCodes" @change="formAreaCodesChange"></e-select-area>
          </a-form-item>
          <a-form-item label="经营类型" v-bind="validateInfos['supplier.businessType.code']">
            <e-select
              placeholder="请选择"
              v-model="formData.supplier.businessType"
              :ajax="{ get: { action: 'GET /common/lookup/:lookupType', params: 'SPLBSTYPE' } }"
              :props="{ label: 'message', value: '' }"
              value-key="code"
              @change="formBusinessChange"
            ></e-select>
          </a-form-item>
          <template v-if="formData.supplier.businessType.code === 'CU'">
            <a-form-item label="经营详情" @click="partsWrapVisible = true">
              <a-input
                placeholder="请选择"
                :value="
                  formData.supplier.parts
                    .concat(formData.supplier.customKeywords)
                    .map((item) => item.message || item.keyword)
                    .join('/')
                "
                class="input-arrow-right"
                readonly
                @click=""
              >
                <template #suffix>
                  <RightOutlined />
                </template>
              </a-input>
            </a-form-item>
          </template>
          <template v-else>
            <a-form-item label="经营详情" @click="brandsWrapVisible = true">
              <a-input
                :disabled="formData.supplier.businessType.code"
                placeholder="请选择"
                :value="formData.supplier.vehicleBrands.join('/')"
                class="input-arrow-right"
                readonly
              >
                <template #suffix>
                  <RightOutlined />
                </template>
              </a-input>
            </a-form-item>
          </template>
          <a-form-item label="推荐人" v-bind="validateInfos['supplier.introducer']">
            <a-input placeholder="请输入" v-model:value="formData.supplier.introducer"></a-input>
          </a-form-item>
        </a-form>
      </a-carousel>
      <a-form>
        <a-form-item>
          <div>
            <a-radio v-model:checked="isAgree"></a-radio>
            <span>我已阅读并同意 </span>
            <a-button type="link" @click="formAgreementClick">《以诺行云报价协议》</a-button>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="flex">
            <a-button type="primary" v-if="carouselViews === 'user'" @click.prevent="formNextClick">下一步</a-button>
            <a-button type="primary" v-if="carouselViews === 'supplier'" @click.prevent="formPrevClick"
              >上一步</a-button
            >
            <a-button
              type="primary"
              v-if="carouselViews === 'supplier'"
              @click.prevent="formSubmitClick"
              class="ml-[10px]"
              >注册</a-button
            >
            <a-button type="link" @click.prevent="formSignClick">使用已有账户登录</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </admin-wrapper>

  <e-drawer title="以诺行询报价平台协议" placement="right" width="60%" v-model:visible="agreementVisible">
    <agreement></agreement>
  </e-drawer>

  <drawer-brands v-model:brands="formData.supplier.vehicleBrands" v-model:visible="brandsWrapVisible"></drawer-brands>
  <drawer-parts
    v-model:parts="formData.supplier.parts"
    v-model:customParts="formData.supplier.customKeywords"
    v-model:visible="partsWrapVisible"
  ></drawer-parts>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { Radio as ARadio, message, Form, Carousel as ACarousel } from 'ant-design-vue'
import { RightOutlined } from '@ant-design/icons-vue'
import { DrawerBrands, DrawerParts } from '@vue-mfe/components'

import { useRouter } from 'vue-router'

import AdminWrapper from '@admin/components/wrapper.vue'
import Agreement from '@admin/components/agreement.vue'

import { useAjax } from '@vue-mfe/utils'
import { debounce } from 'lodash-es'

interface FormData {
  supplier: {
    shortName: string
    areaCodes: any[]
    area: any
    businessType: {
      code: string
    }
    vehicleBrands: any[]
    customKeywords: any[]
    parts: any[]
    introducer: string
  }
  user: {
    name: string
    cellphone: string
    password: string
    password2: string
  }
  token: string
  verifyCode: string | number
}

const router = useRouter()
const carouselRef = ref()
const isAgree = ref(false)

const formData = reactive<FormData>(
  {
    supplier: {
      shortName: '',
      areaCodes: [],
      area: {
        code: ''
      },
      businessType: {
        code: ''
      },
      vehicleBrands: [] as any[],
      customKeywords: [] as any[],
      parts: [] as any[],
      introducer: ''
    },
    user: {
      name: '',
      cellphone: '',
      password: '',
      password2: ''
    },
    token: '',
    verifyCode: ''
  }
)

const { validate, validateInfos } = Form.useForm(
  formData,
  reactive(
    {
      'user.cellphone': [
        { required: true, message: '请输入手机号码' },
        { len: 11, message: '请输11位手机号', trigger: 'blur' },
        { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号', trigger: 'blur' }
      ],
      'user.name': [{ required: true, message: '请输入姓名' }],
      'user.password': [
        {
          required: true,
          // @ts-ignore
          validator: async (rule: any, value: string) => {
            const reg2 = /[\u4e00-\u9fa5/\s]{1,}/g
            const regGroup = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)(\S)+$/
            if (value === '') return Promise.reject('请输入密码')
            else if (value.length < 6 || value.length > 12) return Promise.reject('请输6-12位号码')
            else if (reg2.test(value)) {
              return Promise.reject('不支持空格、中文')
            } else if (!regGroup.test(value)) {
              return Promise.reject('至少包含数字、字母、字符2种组合')
            } else {
              Promise.resolve()
            }
          }
        }
      ],
      'user.password2': [
        {
          required: true,
          // @ts-ignore
          validator: async (rule: any, value: string) => {
            if (value !== formData.user.password) return Promise.reject('两次密码输入不一致')
          }
        }
      ],
      verifyCode: [
        { required: true, message: '请输入验证码' },
        { len: 4, message: '请输入4位验证码' }
      ],
      'supplier.shortName': [{ required: true, message: '请输入公司简称' }],
      'supplier.areaCodes': [
        {
          // @ts-ignore
          validator: () => {
            const data = formData.supplier.areaCodes
            if (!data.length) {
              return Promise.reject('请选择地址')
            } else if (data.length < 2) {
              return Promise.reject('请选择正确地址')
            } else {
              return Promise.resolve()
            }
          },
          trigger: 'blur'
        }
      ],
      'supplier.businessType.code': [{ required: true, message: '请选择经营类型' }],
      'supplier.introducer': [{ required: true, message: '请输入推荐人' }]
    }
  )
)

let verifyCodeTime = ref(0)
let verifyOk = ref(false)
const tiemStars = () => {
  verifyCodeTime.value = 60
  const timer = setInterval(
    () => {
      if (verifyCodeTime.value > 0) {
        verifyCodeTime.value--
      } else {
        clearInterval(timer)
      }
    },
    1000
  )
}
const formVerifyCodeClick = () =>
  validate('user.cellphone').then(
    () => {
      useAjax(
        {
          action: 'GET /supplier/security/verifycode',
          params: () => ({ cellphone: formData.user.cellphone, business: 'REG' }),
          success () {
            tiemStars()
          }
        }
      )
    }
  )
const { run: checkVerifyCode } = useAjax(
  {
    action: 'POST /supplier/security/verifycode',
    params: () => [null, { cellphone: formData.user.cellphone, verifyCode: formData.verifyCode, business: 'REG' }],
    lazy: true,
    success (data) {
      if (data[0]) {
        verifyOk.value = true
        formData.token = data[0]
        message.success('验证码正确')
        carouselViews.value = 'supplier'
        carouselRef.value.next()
      } else {
        verifyOk.value = false
        message.error('验证码错误')
      }
    }
  }
)
const sendCheckVerifyCode = () => {
  validate('verifyCode').then(
    () => {
      checkVerifyCode()
    }
  )
}
const formAreaCodesChange = () => {
  formData.supplier.area = { code: formData.supplier.areaCodes[formData.supplier.areaCodes.length - 1] }
}
const formBusinessChange = () => {
  if (formData.supplier.businessType.code === 'W') {
    formData.supplier.parts = []
    formData.supplier.customKeywords = []
  } else if (formData.supplier.businessType.code === 'CU') formData.supplier.vehicleBrands = []
}

const carouselViews = ref<'user' | 'supplier'>('user')
const formNextClick = debounce(
  () => {
    validate(['user.cellphone', 'verifyCode', 'user.name', 'user.password', 'user.password2']).then(
      () => {
        if (verifyOk.value) {
          carouselViews.value = 'supplier'
          carouselRef.value.next()
        } else {
          sendCheckVerifyCode()
        }
      }
    )
  },
  500
)

const formPrevClick = () => {
  carouselViews.value = 'user'
  carouselRef.value.prev()
}

const formSubmitClick = debounce(
  () => {
    validate(['supplier.shortName', 'supplier.areaCodes', 'supplier.businessType.code', 'supplier.introducer']).then(
      () => {
        if (
          !formData.supplier.vehicleBrands.length &&
          !formData.supplier.parts.length &&
          !formData.supplier.customKeywords.length
        ) {
          return message.warning('请选择经营详情')
        }
        if (!isAgree.value) return message.warning('请先同意《以诺行云报价协议》！')
        useAjax(
          {
            action: 'POST /supplier/register',
            params: () => formData,
            success () {
              message.success('注册成功')
              formSignClick()
            }
          }
        )
      }
    )
  },
  500
)

let brandsWrapVisible = ref(false)
let partsWrapVisible = ref(false)

const formSignClick = () => router.push({ name: 'admin-authenticate' })

const agreementVisible = ref<boolean>(false)
const formAgreementClick = () => (agreementVisible.value = true)
</script>
<style scoped>
.register-form :deep(.ant-input),
.register-form :deep(.ant-btn) {
  height: 40px;
  font-size: 16px;
}
</style>
